import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <section
      className={`hero is-medium has-background-primary has-text-centered`}
    >
      <div className={`hero-body`}>
        <h1 className={`title has-text-light`}>Get in Touch!</h1>
      </div>
    </section>
    <section className="section has-background-info-light">
      <div className={`container`}>
        <h1 className={`title is-family-code is-2 pt-4 has-text-centered`}>
          Let's Talk
        </h1>
        <br />
        <h2 className={`subtitle is-4 has-text-centered`}>
          Tell Us About Your Company!
        </h2>
        <div className={`columns`}>
          <div className={`column is-4-tablet is-offset-2-tablet`}>
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="First Last"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Phone Number</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="tel"
                  placeholder="111-867-5309"
                />
              </div>
            </div>
          </div>

          <div className={`column is-4-tablet`}>
            <div className="field">
              <label className="label">Company Name</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="Your Awesome Company"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Email Address</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="email"
                  placeholder="jen@madeforthisevents.com"
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className={`subtitle is-4 has-text-centered`}>
          Tell Us About Your Event!
        </h2>
        <div className={`columns`}>
          <div className={`column is-4-tablet is-offset-2-tablet`}>
            <div className="field">
              <label className="label">Event Date</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="text"
                  placeholder="MM-DD-YYYY"
                />
              </div>
            </div>
          </div>
          <div className={`column is-4-tablet`}>
            <div className="field">
              <label className="label"># of Guests</label>
              <div className="control">
                <input
                  className="input is-rounded"
                  type="number"
                  placeholder="150"
                />
              </div>
            </div>
          </div>
        </div>

        <h2 className={`subtitle is-4 has-text-centered`}>
          What event services are you looking for?
        </h2>
        <div className={`columns`}>
          <div className={`column is-3-tablet is-offset-2-tablet`}>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Venue
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Catering + Bar Service
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Audio Visual (AV)
            </label>
          </div>
          <div className={`column is-3-tablet`}>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Speakers/Entertainment
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Lodging/Transportation
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Photography + Videography
            </label>
          </div>
          <div className={`column is-3-tablet`}>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Promotional Items
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Rentals
            </label>
            <label className={`checkbox py-2`}>
              <input type="checkbox" /> Design/Decor
            </label>
          </div>
        </div>

        <h2 className={`subtitle is-4 has-text-centered pt-4`}>Event Budget</h2>
        <div className={`columns`}>
          <div className={`column is-4-tablet is-offset-2-tablet`}>
            <div className="field">
              <label className="label">Min $</label>
              <div className="control">
                <div className="select is-rounded is-fullwidth">
                  <select>
                    <option>Select dropdown</option>
                    <option>With options</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={`column is-4-tablet`}>
            <div className="field">
              <label className="label">Max $</label>
              <div className="control">
                <div className="select is-rounded is-fullwidth">
                  <select>
                    <option>Select dropdown</option>
                    <option>With options</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className={`subtitle is-4 has-text-centered`}>Tell us about YOU</h2>
        <div className={`columns`}>
          <div className={`column is-8-tablet is-offset-2-tablet`}>
            <div className="field">
              <label className="label">What is your favorite sitcom?</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Friends, The Office, Brooklyn 99"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                What type of events have you experienced in the past?
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Farm to table, Concerts, State Fair"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">
                What is your biggest hope or desired outcome for this event?
              </label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Change lives, impress my friends, win my girl back"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={`columns`}>
          <div className={`column is-4-tablet is-offset-4-tablet`}>
            <div className="field">
              <div className="control">
                <button className="button is-link is-fullwidth is-rounded is-info is-large">
                  Yay - I'm done!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default ContactPage
